/* !* Full page, non scrollable by default. Somewhat hack for IOS. Only works sometimes... *! */
html,
body {
  overflow: hidden;
  position: relative;
  height: 100%;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Hack to stop crazy zoom out on pinch to zoom bug:
 * https://github.com/googlemaps/js-markerclustererplus/issues/76
*/
.cluster {
  pointer-events: none !important;
}
